.Landing {
  width: 100vw;
  height: 100vh;
  /* background: linear-gradient(90deg, #f8ff00 0%, #3ad59f 100%); */
  background-color: #444;
  position: fixed;
}
.Landing-logo {
  display: block;
  margin: 0 auto;
  width: 64px;
  height: 64px;
}
.Landing-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  color: white;
}
.Landing-content-header {
  font-size: 5rem;
  letter-spacing: 30px;
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0;
}
.Landing-content-time {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  line-height: 3rem;
  background-color: rgba(68, 68, 68, 0.8);
  color: whitesmoke;
}
.Landing .Digit {
  margin: 0 2rem;
  text-align: center;
  padding: 0 .7rem .5rem .7rem;
}
.Landing .Digit-header {
  font-size: .8rem;
}